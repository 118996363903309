import React from 'react';
import Grid from "@mui/material/Grid";
import MeetingChatForm from "../components/MeetingChatForm";

const MeetingChat = () => {
    return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MeetingChatForm/>
                </Grid>
            </Grid>
    );
};

export default MeetingChat;
