import React, {useState} from 'react';
import axios from 'axios';
import {Typography, Box, Grid, Container, TextField, Button, CircularProgress} from '@mui/material';
import Confetti from 'react-confetti';
import './LandingPage.css';
import Header from "./Header";
import ElevaideIndexPageImg from '../images/elevaide1.png';
import config from "../config";

const LandingPage = () => {
    const [email, setEmail] = useState('');
    const [isAdded, setIsAdded] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false); // New state for loading indicator

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleJoinClick = () => {
        if (!validateEmail(email)) {
            setError('Please enter a valid email');
            return;
        }

        // Show loading indicator and message
        setIsLoading(true);
        setError('Wait for Confetti ...');

        // Perform the axios call in the background
        axios.post(`${config.backendUrl}/waiting-list/`, {email})
            .then(response => {
                setIsLoading(false);
                setError('');
                setIsAdded(true);
                setShowConfetti(true);
                setTimeout(() => setShowConfetti(false), 8000); // Stop confetti after 8 seconds
            })
            .catch(error => {
                setIsLoading(false);
                setError('Error adding to the waiting list. Please try again.');
            });
    };

    return (
        <Box className="main-container">
            {showConfetti && <Confetti/>}
            <Header/>
            <Container maxWidth="lg" className="landing-page">
                <Grid container spacing={1} className="content-container">
                    <Grid item xs={12} md={6} className="left-side">
                        <Box className="hero-unit">
                            <Typography variant="h3" fontWeight="bold" marginBottom="1rem" className="hero-title">
                                Building Stronger Teams with Human-Centric Connections
                            </Typography>
                            <Typography variant="body1" className="hero-subtitle">
                                Our mission is to humanize work by cultivating deeper human connections. Allowing
                                organizations, teams and individuals to grow and thrive
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className="right-side">
                        <Box className="right-section">
                            <Typography variant="h6" fontWeight="bold" marginBottom="2rem" className="right-title">
                                {isAdded ? "Congratulations! You're added to the waiting list" : "We're cooking something up!"}
                            </Typography>
                            {!isAdded && (
                                <>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter Email"
                                        fullWidth
                                        className="email-input"
                                        value={email}
                                        onChange={handleEmailChange}
                                        InputProps={{
                                            style: {
                                                borderRadius: '7px',
                                            },
                                        }}
                                    />
                                    <Typography
                                        className={`error-message ${isLoading ? 'loading-message' : ''}`}
                                        marginBottom={error ? "1rem" : "2.5rem"}
                                    >{error}</Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        className="join-button"
                                        onClick={handleJoinClick}
                                        disabled={isLoading} // Disable button when loading
                                    >
                                        {isLoading ? <CircularProgress size={24} className="loading-spinner" /> : "Join waiting list"}
                                    </Button>
                                </>
                            )}
                            <Box className="link-container">
                                <a href="/privacy" className="footer-link">Privacy Policy</a>
                                <a href="#" className="footer-link">Terms of Use</a>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box className="background-image-container">
                <img src={ElevaideIndexPageImg} alt="Background" className="background-image"/>
            </Box>
        </Box>
    );
};

export default LandingPage;
