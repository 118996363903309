import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import SignIn from "./auth/signIn"
import Product from "./product/Product";
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {useAuth, AuthProvider} from './auth/AuthProvider';
import axios from "axios";
import AuthCallback from "./auth/AuthCallback";
import LandingPage from "./landingpage/LandingPage";
import PrivacyPolicy from "./landingpage/PrivacyPolicy";

axios.defaults.withCredentials = true;

const ProtectedRoute = ({children}) => {

    const {user} = useAuth(); // Use the authentication context

    if (!user || !user.isAuthenticated) {
        return <Navigate to="/" replace/>;
    }

    return children;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthProvider>
            {/*<App />*/}
            <Router>
                <Routes>
                    <Route exact path="/" element={<LandingPage/>}/>
                    <Route exact path="/privacy" element={<PrivacyPolicy/>}/>
                    <Route path="/auth/callback" element={<AuthCallback/>}/> {/* New route */}
                    <Route path="/product/*" element={<ProtectedRoute><Product/></ProtectedRoute>}/>
                    <Route path="/sign-in-3b9fAll7c5aY0u8d1eN33d4e6a8iS7d5b9cL0V36a7d8e9b3" element={<SignIn/>}/>
                    {/*<Route path="/sign-in" element={<SignIn/>}/>*/}
                    {/*<Route path="/demo" element={<ProtectedRoute><DemoPage/></ProtectedRoute>}/>*/}
                    {/*<Route path="/about" component={AboutPage}/>*/}
                    {/* Add more routes as needed */}
                </Routes>
            </Router>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// ReactDOM.render(
//   <React.StrictMode>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
//
// reportWebVitals();
