import React from 'react';
import {Box, Typography, Card, CardContent} from '@mui/material';
import './TopicInfoCard.css';

export const InfoItem = ({label, value}) => (
    <Box className="info-item">
        <Typography variant="subtitle2" className="info-label">
            {label}
        </Typography>
        <Typography variant="body2" className="info-value">
            {value ?? 'N/A'}
        </Typography>
    </Box>
);

const TopicInfoCard = ({topic}) => {
    return (
        <Card className="topic-info-card">
            <CardContent>
                <Typography variant="h6" className="topic-name">{topic.name}</Typography>
                <InfoItem label="Summary" value={topic.summary}/>
                {topic.key_points && topic.key_points.length > 0 && (
                    <Box className="key-points">
                        <Typography variant="subtitle2" className="key-points-label">
                            Key Points
                        </Typography>
                        <ul>
                            {topic.key_points.map((point, index) => (
                                <li key={index}>
                                    <Typography variant="body2">{point}</Typography>
                                </li>
                            ))}
                        </ul>
                    </Box>
                )}
                <InfoItem label="Sentiment Analysis" value={topic.sentimentReasoning}/>
                <InfoItem
                    label="Importance Score"
                    value={topic.importance_score ? topic.importance_score.toFixed(2) : undefined}
                />
                <InfoItem
                    label="Confidence Score"
                    value={topic.confidence_score ? topic.confidence_score.toFixed(2) : undefined}
                />
            </CardContent>
        </Card>
    );
};

export default TopicInfoCard;