import axios from 'axios';
import Cookies from 'js-cookie';
import {createContext, useContext, useEffect, useState} from 'react';
import config from '../config';

const AuthContext = createContext(null);

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.headers.post['X-CSRFToken'] = Cookies.get('csrftoken');
axios.defaults.withCredentials = true;

// Set up axios to automatically include the token in the Authorization header
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Token ${token}` : '';
    return config;
});

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });
    const [error, setError] = useState('');

    useEffect(() => {
        const checkAuth = () => {
            const savedUser = localStorage.getItem('user');
            if (savedUser) {
                setUser(JSON.parse(savedUser));
            }
        };
        checkAuth();
    }, []);

    const handleLogin = (token, email) => {
        try {
            if (token && email) {
                localStorage.setItem('user', JSON.stringify({email, isAuthenticated: true}));
                localStorage.setItem('token', token); // Save the token to local storage
                setUser({email, isAuthenticated: true});
                setError('');
            }
        } catch (error) {
            setError('Error logging in with Google');
        }
    };

    const logout = () => {
        axios.post(`${config.backendUrlApiV1}/logout/`, {}, {
            withCredentials: true,
        }).then(() => {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            setUser(null);
        }).catch(error => {
            console.error('Logout error:', error);
        });
    };

    return (
        <AuthContext.Provider value={{user, error, handleLogin, logout}}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
