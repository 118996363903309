import React, {useState} from 'react';
import {Fab, Box, List, ListItem, ListItemText} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from 'react-router-dom'; // Import NavLink
import './Actionbutton.css';
import {useAuth} from "../../auth/AuthProvider"; // Import the CSS file
import Settings from './Settings';
import Feedback from "./Feedback";

const ActionButton = () => {
    const {user, logout} = useAuth();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [feedbackOpen, setFeedbackOpen] = useState(false);


    const handleClick = () => {
        setOpen(!open);
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const handleSettingsOpen = () => {
        setSettingsOpen(true);
        setOpen(false); // Close the action button popover when opening settings
    };

    const handleSettingsClose = () => {
        setSettingsOpen(false);
    };

    const handleFeedbackOpen = () => {
        setFeedbackOpen(true);
        setOpen(false); // Close the action button popover when opening feedback
    };

    const handleFeedbackClose = () => {
        setFeedbackOpen(false);
    };

    return (
        <div>
            <Fab
                size="small" // Set the size to small
                aria-label="help"
                onClick={handleClick}
                className={`fab-button ${open ? 'fab-button-open' : ''}`}
            >
                {open ? <CloseIcon className="fab-icon"/> : <HelpOutlineIcon className="fab-icon"/>}
            </Fab>
            {open && (
                <Box className="popover-box">
                    <List>
                        <ListItem button onClick={handleFeedbackOpen}>
                            <ListItemText primary="Give feedback" />
                        </ListItem>
                        <ListItem button onClick={handleSettingsOpen}>
                            <ListItemText primary="Settings"/>
                        </ListItem>
                        <ListItem button onClick={handleLogout} className="MuiListItem-divider">
                            <ListItemText primary="Log out"/>
                        </ListItem>
                    </List>
                </Box>
            )}
            {settingsOpen && <Settings open={settingsOpen} onClose={handleSettingsClose}/>}
            {feedbackOpen && <Feedback open={feedbackOpen} onClose={handleFeedbackClose} />}
        </div>
    );
};

export default ActionButton;
