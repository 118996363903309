import React, { useState } from 'react';
import { Box, Typography, Snackbar, Alert, CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './home/Home.css'; // Import existing CSS for curved view and header
import './Upload.css';
import axios from "axios";
import config from "../config"; // Import new CSS for upload-specific styles
import {useNavigate} from "react-router-dom";

const Upload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const navigate = useNavigate(); // Initialize useNavigate

    const handleOpenSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const getSignedUrl = async (file) => {
        const response = await axios.post(`${config.backendUrlApiV1}/generate-signed-url/`, {
            file_name: file.name,
            content_type: file.type,
        });
        return response.data.url;
    };

    // const uploadFileToGCS = async (file, signedUrl) => {
    //     try {
    //       console.log('Uploading file to URL:', signedUrl);
          
    //       // First, send an OPTIONS request
    //       await axios.options(signedUrl);
          
    //       // Then, send the PUT request
    //       const response = await axios.put(signedUrl, file, {
    //         headers: {
    //           'Content-Type': file.type,
    //         },
    //         withCredentials: false,
    //       });
    //       console.log('File uploaded to GCS', response);
    //       return response;
    //     } catch (error) {
    //       console.error('Error uploading file to GCS:', error);
    //       if (error.response) {
    //         console.error('Response data:', error.response.data);
    //         console.error('Response status:', error.response.status);
    //         console.error('Response headers:', error.response.headers);
    //       } else if (error.request) {
    //         console.error('Request:', error.request);
    //       } else {
    //         console.error('Error message:', error.message);
    //       }
    //       throw error;
    //     }
    //   };
      const uploadFileToGCS = async (file, signedUrl) => {
        try {
          console.log('Uploading file to URL:', signedUrl);
          const response = await axios.put(signedUrl, file, {
            headers: {
              'Content-Type': file.type,
              'Origin': 'http://localhost:8080'
            },
            withCredentials: false,
          });
          console.log('File uploaded to GCS', response);
          return response;
        } catch (error) {
          console.error('Error uploading file to GCS:', error);
          if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
          } else if (error.request) {
            console.error('Request:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
          throw error;
        }
      };
    

    const notifyBackendForProcessing = async (file) => {
        await axios.post(`${config.backendUrlApiV1}/upload-and-process-gcs-file/`, {
            file_name: file.name,
        });
    };

    // const onUpload = async (file) => {
    //     setIsLoading(true);
    //     try {
    //       const signedUrl = await getSignedUrl(file);
    //       console.log('Received signed URL:', signedUrl);
    //       await uploadFileToGCS(file, signedUrl);
    //       console.log('File uploaded to GCS');
    //       await notifyBackendForProcessing(file);
    //       console.log('File is being processed');
    //       handleOpenSnackbar('File uploaded to GCS and is being processed.', 'success');
    //     } catch (error) {
    //       console.error('Error in onUpload:', error);
    //       handleOpenSnackbar('Error uploading file. Please try again.', 'error');
    //     } finally {
    //       setIsLoading(false);
    //     }
    //   };
    const onUpload = async (file) => {
        setIsLoading(true); // Start loading
        try {
            if (file.size > 32 * 1024 * 1024) { // If file size is greater than 32MB
                const signedUrl = await getSignedUrl(file);
                console.log('Received signed URL:', signedUrl);
                await uploadFileToGCS(file, signedUrl);
                console.log('File uploaded to GCS');
                await notifyBackendForProcessing(file);
                console.log('File is being processed');
                handleOpenSnackbar('File uploaded to GCS and is being processed.', 'success');
            } else {
                const formData = new FormData();
                formData.append('file', file);
                handleOpenSnackbar('File is being processed, it may take a few minutes before you see the results', 'success');
                const uploadResponse = await axios.post(`${config.backendUrlApiV1}/upload-and-process/`, formData);
                const data = uploadResponse.data;
                if (uploadResponse.status === 200) {
                    handleOpenSnackbar(data.message, 'success');
                }
            }
        } catch (error) {
            handleOpenSnackbar(error.response.data.error, 'error');
            console.error('Error:', error);
        } finally {
            setIsLoading(false); // End loading
        }
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > config.maxFileSize) {
                handleOpenSnackbar('File size exceeds the 100 MB limit', 'error');
                return;
            }
            setSelectedFile(file);
            onUpload(file);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            if (file.size > config.maxFileSize) {
                handleOpenSnackbar('File size exceeds the 100 MB limit', 'error');
                return;
            }
            setSelectedFile(file);
            onUpload(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    return (
        <Box className="curved-view upload-container">
            <Typography variant="h4" className="header-title">
                Upload an audio or video file to analyse meeting
            </Typography>
            <Typography variant="body1" className="header-subtitle">
                Depending on the size of the audio file, it will be processed & transcribed in 10 - 15 minutes
            </Typography>
            <Box
                className={`dotted-box ${isLoading ? 'disabled' : ''}`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={() => !isLoading && document.getElementById('fileInput').click()}
                style={{ position: 'relative' }} // Added to position the loading indicator
            >
                {isLoading && (
                    <Box className="loading-overlay">
                        <CircularProgress className="loading-spinner" />
                    </Box>
                )}
                {!isLoading && (
                    <>
                        <CloudUploadIcon className="upload-icon" />
                        <Typography variant="body1" className="upload-text">
                            Drag and drop MP3, M4A, WAV or MP4 file here, or select files to upload.
                            Limit 100MB per file.
                        </Typography>
                    </>
                )}
                <input
                    id="fileInput"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                />
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Upload;
