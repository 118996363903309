import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    Button,
    Snackbar,
    Alert,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Typography,
    Paper,
    TextField,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import axios from 'axios';
import config from '../config';

const ChatModes = {
    MEETING_CHAT: 'Meeting Chat',
    LITERATURE_CHAT: 'Literature Chat',
};

const   MeetingChatForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMeetingId, setSelectedMeetingId] = useState('');
    const [transcript, setTranscript] = useState([]);
    const [showMessage, setShowMessage] = useState({show: false, type: '', message: ''});
    const [chatMessages, setChatMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [meetings, setMeetings] = useState([]);
    const [chatMode, setChatMode] = useState(ChatModes.MEETING_CHAT); // New state for chat mode
    const chatContainerRef = useRef(null);

    useEffect(() => {
        fetchMeetings();
    }, []);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatMessages]);

    const fetchMeetings = async () => {
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/get-meetings/`);
            console.log('Fetched meetings:', response.data.menuMeetings);
            setMeetings(response.data.menuMeetings);
        } catch (error) {
            console.error('Error fetching meetings:', error);
            setShowMessage({show: true, type: 'error', message: 'Error fetching meetings. Please try again.'});
        }
    };

    const fetchTranscript = async (selectedMeetingId) => {
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/get-transcript`, {
                params: {selectedMeetingId},
            });
            return response.data.transcript;
        } catch (error) {
            console.error('Error fetching transcript:', error);
            throw error;
        }
    };

    const handleMeetingChange = async (event) => {
        const meetingId = event.target.value;
        setSelectedMeetingId(meetingId);
        setIsLoading(true);
        setShowMessage({show: false, type: '', message: ''});
        setChatMessages([]); // Clear chat messages when changing meetings

        try {
            const fetchedTranscript = await fetchTranscript(meetingId);
            console.log('Fetched transcript:', fetchedTranscript);
            setTranscript(fetchedTranscript);
            setIsLoading(false);
            setShowMessage({show: true, type: 'success', message: 'Transcript fetched successfully!'});
        } catch (error) {
            setIsLoading(false);
            setShowMessage({show: true, type: 'error', message: 'Error fetching transcript. Please try again.'});
        }
    };

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') return;
        setShowMessage({...showMessage, show: false});
    };

    const handleSendMessage = async () => {
        if (!userInput.trim()) return;

        const newUserMessage = {role: 'user', content: userInput};
        const updatedChatMessages = [...chatMessages, newUserMessage];
        setChatMessages(updatedChatMessages);
        setUserInput('');

        try {
            // Decide API endpoint based on chat mode
            const apiUrl = chatMode === ChatModes.MEETING_CHAT
                ? `${config.backendUrlApiV1}/meeting-chat/`
                : `${config.backendUrlApiV1}/rag-chat/`;
            console.log('API URL:', apiUrl);
            const response = await axios.post(apiUrl, {
                message: userInput,
                conversation_history: updatedChatMessages,
            });
            console.log('Response:', response);

            const botMessage = {role: 'assistant', content: response.data.reply};
            setChatMessages((prevMessages) => [...prevMessages, botMessage]);
        } catch (error) {
            console.error('Error sending message to chatbot:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
            }

            setShowMessage({
                show: true,
                type: 'error',
                message: 'Error communicating with the chatbot. Please try again.'
            });
        }
    };

    const renderTranscript = () => (
        <Paper elevation={3} sx={{p: 2, height: '600px', overflow: 'auto'}}>
            {transcript.map((item, index) => (
                <Box key={index} sx={{mb: 4}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {item.speakerName && (
                            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                                {item.speakerName}
                            </Typography>
                        )}
                        <Typography
                            variant="body1"
                            sx={{
                                ml: 2,
                                whiteSpace: 'pre-wrap',
                                color: 'grey.600',
                                '& .timestamp': {
                                    bgcolor: 'purple',
                                    color: 'white',
                                    px: 0.5,
                                    borderRadius: 1,
                                    fontSize: '0.8em',
                                    mr: 1,
                                },
                            }}
                        >
                            {item.startTimeFormatted}
                        </Typography>
                    </Box>
                    <Typography
                        variant="body1"
                        sx={{
                            ml: item.speakerName ? 4 : 0,
                            whiteSpace: 'pre-wrap',
                            mt: 1,
                        }}
                    >
                        {item.text}
                    </Typography>
                </Box>
            ))}
        </Paper>
    );

    const renderChatMessages = () => (
        <Paper elevation={3} sx={{p: 2, height: '500px', overflow: 'auto'}} ref={chatContainerRef}>
            {chatMessages.map((message, index) => (
                <Box key={index} sx={{mb: 2, textAlign: message.role === 'user' ? 'right' : 'left'}}>
                    <Typography
                        variant="body1"
                        sx={{
                            display: 'inline-block',
                            bgcolor: message.role === 'user' ? 'primary.main' : 'grey.300',
                            color: message.role === 'user' ? 'white' : 'black',
                            p: 1,
                            borderRadius: 2,
                        }}
                    >
                        {message.content}
                    </Typography>
                </Box>
            ))}
        </Paper>
    );

    return (
        <Box>
            {/* Toggle between Meeting Chat and Literature Chat */}
            <ToggleButtonGroup
                value={chatMode}
                exclusive
                onChange={(event, newMode) => setChatMode(newMode)}
                sx={{mb: 2}}
            >
                <ToggleButton value={ChatModes.MEETING_CHAT}>Meeting Chat</ToggleButton>
                <ToggleButton value={ChatModes.LITERATURE_CHAT}>Literature Chat</ToggleButton>
            </ToggleButtonGroup>

            {chatMode === ChatModes.MEETING_CHAT && (
                <FormControl fullWidth margin="normal">
                    <InputLabel id="meeting-select-label">Select Meeting</InputLabel>
                    <Select
                        labelId="meeting-select-label"
                        id="meeting-select"
                        value={selectedMeetingId}
                        onChange={handleMeetingChange}
                        required
                    >
                        {meetings.map((meeting) => (
                            <MenuItem key={meeting.id} value={meeting.id}>
                                {`${meeting.name} - ${meeting.createdAt}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>Chat</Typography>
                    {renderChatMessages()}
                    <Box sx={{mt: 2, display: 'flex'}}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Type your message here..."
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                        />
                        <Button
                            variant="contained"
                            onClick={handleSendMessage}
                            sx={{ml: 1}}
                        >
                            Send
                        </Button>
                    </Box>
                </Grid>
                {chatMode === ChatModes.MEETING_CHAT && (
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>Meeting Transcript</Typography>
                        {isLoading ? (
                            <Typography variant="body1">Loading transcript...</Typography>
                        ) : (
                            transcript.length > 0 && renderTranscript()
                        )}
                    </Grid>
                )}
            </Grid>

            <Snackbar open={showMessage.show} autoHideDuration={6000} onClose={handleCloseMessage}>
                <Alert onClose={handleCloseMessage} severity={showMessage.type} sx={{width: '100%'}}>
                    {showMessage.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default MeetingChatForm;
