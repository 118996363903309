import purple from './images/logos/Purple/Mark.png';
import purpleWord from './images/logos/Purple/Wordmark.png';
import blue from './images/logos/Blue/Mark.png';
import blueWord from './images/logos/Blue/Wordmark.png';
import green2 from './images/logos/Green2/Mark.png';
import greenWord2 from './images/logos/Green2/Wordmark.png';
import green5 from './images/logos/Green5/Mark.png';
import greenWord5 from './images/logos/Green5/Wordmark.png';
import green6 from './images/logos/Green6/Mark.png';
import greenWord6 from './images/logos/Green6/Wordmark.png';
import orange from './images/logos/Orange/Mark.png';
import orangeWord from './images/logos/Orange/Wordmark.png';
import peach from './images/logos/Peach/Mark.png';
import peachWord from './images/logos/Peach/Wordmark.png';



const logoPairs = [
    { logo: purple, elevaide: purpleWord },
    { logo: blue,   elevaide: blueWord },
    { logo: green2, elevaide: greenWord2 },
    { logo: green5, elevaide: greenWord5 },
    { logo: green6, elevaide: greenWord6 },
    { logo: orange, elevaide: orangeWord },
    { logo: peach,  elevaide: peachWord },
];

export const getRandomLogoPair = () => {
    const randomIndex = Math.floor(Math.random() * logoPairs.length);
    return logoPairs[randomIndex];
};
