import React, {useEffect, useState} from 'react';
import {Box, Typography, CircularProgress} from '@mui/material';
import './Meetings.css';
import axios from "axios";
import config from "../config";
import {useNavigate} from "react-router-dom";
const fontFamilies = ['Roboto Slab', 'Raleway', 'Montserrat', 'Playfair Display', 'Poppins'];
const fontIndexHeader = 2; // Change this index to use different fonts from the array
const fontIndexBody = 1; // Change this index to use different fonts from the array

const Meetings = () => {
    const [meetings, setMeetings] = useState([]);
    const [loading, setLoading] = useState(false); // State for loading indicator
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        fetchMeetings();
    }, []);

    const fetchMeetings = async () => {
        setLoading(true); // Show loading indicator
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/get-meetings-weekly-view`);
            const data = response.data;
            if (data.menuMeetings) {
                setMeetings(data.menuMeetings);
            }
        } catch (error) {
            console.error('Error fetching menu items:', error);
        } finally {
            setLoading(false); // Hide loading indicator
        }
    };

    const handleMeetingClick = (meetingId) => {
        navigate('/Product/Home', {state: {selectedMeetingId: meetingId}}); // Navigate with state
    };

    return (
        <Box className="table-container">
            <Box className="table-header">
                <Typography variant="subtitle1"
                            style={{flex: 1, fontFamily: fontFamilies[fontIndexHeader]}}>Meeting</Typography>
                <Typography variant="subtitle1"
                            style={{flex: 1, fontFamily: fontFamilies[fontIndexHeader]}}>Date</Typography>
                <Typography variant="subtitle1"
                            style={{flex: 1, fontFamily: fontFamilies[fontIndexHeader]}}>Time</Typography>
                <Typography variant="subtitle1"
                            style={{flex: 1, fontFamily: fontFamilies[fontIndexHeader]}}>Duration</Typography>
            </Box>
            {loading ? (
                <Box className="loading-container">
                    <CircularProgress />
                </Box>
            ) : (
                meetings.length === 0 ? (
                    <Box className="table-header center-align">
                        <Typography variant="subtitle2" style={{
                            flex: 1,
                            textAlign: 'center',
                            fontFamily: fontFamilies[fontIndexHeader]
                        }}>Hooorayy!! You have no meetings</Typography>
                    </Box>
                ) : (
                    meetings.map((week) => (
                        <React.Fragment key={week.week}>
                            <Box className="table-header center-align">
                                <Typography variant="subtitle2" style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontFamily: fontFamilies[fontIndexHeader]
                                }}>{week.week}</Typography>
                            </Box>
                            {week.items.map((meeting, index) => (
                                <Box
                                    key={index}
                                    className="table-row"
                                    onClick={() => handleMeetingClick(meeting.id)}
                                >
                                    <Typography variant="body2" style={{
                                        flex: 1,
                                        fontFamily: fontFamilies[fontIndexBody]
                                    }}>{meeting.name}</Typography>
                                    <Typography variant="body2" style={{
                                        flex: 1,
                                        fontFamily: fontFamilies[fontIndexBody]
                                    }}>{meeting.date}</Typography>
                                    <Typography variant="body2" style={{
                                        flex: 1,
                                        fontFamily: fontFamilies[fontIndexBody]
                                    }}>{meeting.time}</Typography>
                                    <Typography variant="body2" style={{
                                        flex: 1,
                                        fontFamily: fontFamilies[fontIndexBody]
                                    }}>{meeting.duration}</Typography>
                                </Box>
                            ))}
                        </React.Fragment>
                    ))
                ))}
        </Box>
    );
};

export default Meetings;
