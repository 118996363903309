// frontend/src/product/Product.js
import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import Sidebar from './Sidebar';
import Home from './home/Home';
import MeetingChat from './MeetingChat';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Meetings from "./Meetings";
import Upload from "./Upload";
import Actionbutton from "./settings/Actionbutton";

const Product = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{backgroundColor: '#F6F8FC', minHeight: '100vh'}}> {/* Set background color here */}
                <Grid container>
                    {!isMobile && (
                        <Grid item xs={0.6}>
                            <Sidebar/>
                        </Grid>
                    )}
                    <Grid item xs={isMobile ? 12 : 11.4}>
                        {/*<Box sx={{flexGrow: 1, p: 1, display: 'flex'}}>*/}
                        <Box sx={{flexGrow: 1, p: 1}}>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="home" element={<Home/>}/>
                                <Route path="meet" element={<Meetings/>}/>
                                <Route path="upload" element={<Upload/>}/>
                                {/*<Route path="feedback" element={<FeedbackT/>}/>*/}
                                <Route path="meeting-chat" element={<MeetingChat/>}/>
                            </Routes>
                        </Box>
                    </Grid>
                </Grid>
                {/*{isMobile && <ActionButton/>} /!* Render ActionButton only on mobile *!/*/}
                <Actionbutton/>
            </Box>
        </ThemeProvider>
    );
};

export default Product;
