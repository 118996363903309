import React, {useEffect, useState, useMemo} from 'react';
import {Box, Typography, Chip, Popover} from '@mui/material';
import './HeartbeatTimeline.css';
import config from "../../../config";
import TopicInfoCard from './TopicInfoCard';

const HeartbeatTimeline = ({transcriptData, duration, currentTime}) => {
    const [speakerColors, setSpeakerColors] = useState({});
    const [mergedTopics, setMergedTopics] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState(null);
    const [significantEvents, setSignificantEvents] = useState([]);

    const open = Boolean(anchorEl);
    const {transcript, metaData} = transcriptData || {};
    const {utterances = [], speakerNames = []} = transcript || {};

    const sortedSpeakerNames = useMemo(() =>
            [...speakerNames].sort((a, b) => a.localeCompare(b)),
        [speakerNames]);

    const colors = config.colorsBright;

    useEffect(() => {
        if (sortedSpeakerNames.length > 0) {
            const newSpeakerColors = {};
            sortedSpeakerNames.forEach((speaker, index) => {
                newSpeakerColors[speaker] = colors[index % colors.length];
            });
            setSpeakerColors(newSpeakerColors);
        }
    }, [sortedSpeakerNames, colors]);

    useEffect(() => {
        const extractedTopics = utterances
            .filter(entry => entry.topic && entry.topic !== "No Topic")
            .map(entry => ({
                name: entry.topic,
                start: entry.start,
                end: entry.end,
                summary: entry.topicInfo?.summary,
                importance_score: entry.topicInfo?.importanceScore,
                confidence_score: entry.topicInfo?.confidenceScore,
                sentimentReasoning: entry.topicInfo?.sentimentReasoning,
                key_points: entry.topicInfo?.keyPoints,
            }));

        const merged = [];
        let currentTopic = null;

        extractedTopics.forEach((topic) => {
            if (!currentTopic || currentTopic.name !== topic.name) {
                if (currentTopic) {
                    merged.push(currentTopic);
                }
                currentTopic = {...topic};
            } else {
                currentTopic.end = topic.end;
            }
        });

        if (currentTopic) {
            merged.push(currentTopic);
        }

        setMergedTopics(merged);

        // Extract significant events
        const extractedEvents = utterances
            .filter(entry => entry.event && entry.event !== "No Event")
            .map(entry => ({
                eventName: entry.event,
                start: entry.start,
                end: entry.end,
                category: entry.eventInfo?.category,
                analysis: entry.eventInfo?.analysis,
                importanceScore: entry.eventInfo?.importanceScore,
                emotionalTone: entry.eventInfo?.emotionalTone,
                recommendation: entry.eventInfo?.recommendation,
                relevantTheory: entry.eventInfo?.relevantTheory,
                confidenceScore: entry.eventInfo?.confidenceScore,
            }));

        setSignificantEvents(extractedEvents);

    }, [utterances]);

    const handlePopoverOpen = (event, content) => {
        setAnchorEl(event.currentTarget);
        setPopoverContent(content);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverContent(null);
    };

    if (!transcriptData || !transcript || utterances.length === 0) {
        return <Typography>Loading transcript data...</Typography>;
    }

    return (
        <Box className="heartbeat-timeline">

            {/* <Box className="speaker-container">
        <Typography variant="body2" className="speaker-name">Significant Events</Typography>
        <Box className="heartbeat-row">
          <Box className="timeline">
            {significantEvents.map((event, index) => (
              <Chip
                key={index}
                label={event.eventName}
                size="small"
                className="timeline-chip significant-event"
                style={{
                  left: `${(event.start / (duration * 1000)) * 100}%`,
                  width: `${((event.end - event.start) / (duration * 1000)) * 100}%`,
                  backgroundColor: colors[index % colors.length],
                }}
                onMouseEnter={(e) => handlePopoverOpen(e, <SignificantEventInfoCard event={event} />)}
                onMouseLeave={handlePopoverClose}
              />
            ))}
          </Box>
        </Box>
      </Box> */}

            <Box className="speaker-container">
                <Typography variant="body2" className="speaker-name">Topics</Typography>
                <Box className="heartbeat-row">
                    <Box className="timeline">
                        {mergedTopics.map((topic, index) => (
                            <Chip
                                key={index}
                                label={topic.name}
                                size="small"
                                className="timeline-chip topic"
                                style={{
                                    left: `${(topic.start / (duration * 1000)) * 100}%`,
                                    width: `${((topic.end - topic.start) / (duration * 1000)) * 100}%`,
                                    backgroundColor: colors[index % colors.length],
                                }}
                                onMouseEnter={(e) => handlePopoverOpen(e, <TopicInfoCard topic={topic}/>)}
                                onMouseLeave={handlePopoverClose}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>

            {sortedSpeakerNames.map((speaker) => (
                <Box key={speaker} className="speaker-container">
                    <Typography variant="body2" className="speaker-name">{speaker}</Typography>
                    <Box className="heartbeat-row">
                        <Box className="timeline">
                            {utterances
                                .filter(entry => entry.speakerName === speaker)
                                .map((entry, index) => (
                                    <Box
                                        key={index}
                                        className="utterance"
                                        style={{
                                            left: `${(entry.start / (duration * 1000)) * 100}%`,
                                            width: `${((entry.end - entry.start) / (duration * 1000)) * 100}%`,
                                            backgroundColor: speakerColors[speaker],
                                        }}
                                    />
                                ))}
                        </Box>
                    </Box>
                </Box>
            ))}
            <Box
                className="playhead"
                style={{
                    left: `${(currentTime / duration) * 100}%`,
                    height: `${(sortedSpeakerNames.length + 1) * 52}px`,
                }}
            />
            <Popover
                id="mouse-over-popover"
                className="styled-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {popoverContent}
            </Popover>
        </Box>
    );
};

export default HeartbeatTimeline;