import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../../config';
import {
    Box,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
} from '@mui/material';
import './Settings.css';

const meetingOptions = [
    {
        value: 'all',
        label: 'Join all meetings',
        description: 'The meeting assistant attends all meetings in your team calendar',
    },
    {
        value: 'recurring',
        label: 'Join recurring meetings',
        description: 'The meeting assistant attends all recurring meetings in your team calendar',
    },
    {
        value: 'accepted',
        label: 'Join all accepted meetings',
        description: 'The meeting Assistant attends all meetings accepted by team members',
    },
    {
        value: 'organized',
        label: 'Join meetings organized by the user',
        description: 'The meeting assistant attends all meetings organized by team members',
    },
    {
        value: 'invited',
        label: 'Join when invited',
        description: 'The meeting assistant attends when manually added to the meeting',
    },
];

const Settings = ({open, onClose}) => {
    const [meetingOption, setMeetingOption] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${config.backendUrlApiV1}/settings/`);
                const data = response.data;
                setMeetingOption(data.meeting);
            } catch (error) {
                console.error('Error fetching settings:', error);
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            setLoading(true);
            fetchSettings();
        }
    }, [open]);

    const handleMeetingOptionChange = (event) => {
        setMeetingOption(event.target.value);
    };

    const handleSaveSettings = async () => {
        try {
            await axios.post(`${config.backendUrlApiV1}/settings/`, {meeting: meetingOption});
            onClose();
        } catch (error) {
            console.error('Error saving settings:', error);
        }
    };

    return (
        <div className={`modal-overlay ${open ? 'fade-in' : ''}`} onClick={onClose}>
            <Box className="modal-box" onClick={(e) => e.stopPropagation()}>
                <Typography variant="h5" className="modal-title">Settings</Typography>
                <Typography variant="h6" className="settings-subtitle">Meeting settings</Typography>
                <Typography variant="body2" className="settings-description">Choose which meetings to join</Typography>
                <RadioGroup value={meetingOption} onChange={handleMeetingOptionChange}>
                    {meetingOptions.map((option) => (
                        <div key={option.value}>
                            <FormControlLabel value={option.value} control={<Radio/>} label={option.label}/>
                            <Typography variant="body2" className="option-description">
                                {option.description}
                            </Typography>
                        </div>
                    ))}
                </RadioGroup>
                <Button
                    variant="contained"
                    color="primary"
                    className="save-button"
                    onClick={handleSaveSettings}
                    disabled={loading} // Disable button while loading
                >
                    Save changes
                </Button>
            </Box>
        </div>
    );
};

export default Settings;
