import React, {useState, useEffect, useRef} from 'react';
import {Box, Button, Typography, Paper, TextField, Snackbar, Alert} from '@mui/material';
import axios from 'axios';
import config from '../../../config';
import './ChatWithAI.css';

const ChatWithAI = ({transcriptData}) => {
    const [chatMessages, setChatMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [showMessage, setShowMessage] = useState({show: false, type: '', message: ''});
    const chatContainerRef = useRef(null);

    const {transcript = {utterances: [], speaker_names: []}, metaData = {}} = transcriptData;

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatMessages]);

    const handleSendMessage = async () => {
        if (!userInput.trim()) return;
        const newUserMessage = {role: 'user', content: userInput};
        const updatedChatMessages = [...chatMessages, newUserMessage];
        setChatMessages(updatedChatMessages);
        setUserInput('');

        console.log('Testing ')
        console.log(userInput)
        console.log(updatedChatMessages)
        console.log(transcript)
        console.log(metaData)

        try {
            const response = await axios.post(`${config.backendUrlApiV1}/meeting-chat/`, {
                message: userInput,
                conversation_history: updatedChatMessages,
                meeting_id: metaData.meetingId
            });
            const botMessage = {role: 'assistant', content: response.data.reply};
            setChatMessages((prevMessages) => [...prevMessages, botMessage]);
        } catch (error) {
            console.error('Error sending message to chatbot:', error);
            setShowMessage({
                show: true,
                type: 'error',
                message: 'Error communicating with the chatbot. Please try again.'
            });
        }
    };

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') return;
        setShowMessage({...showMessage, show: false});
    };

    return (
        <Box className="chat-with-ai-container">
            <Box className="chat-messages-wrapper">
                <Paper elevation={3} className="chat-messages" ref={chatContainerRef}>
                    {chatMessages.map((message, index) => (
                        <Box key={index} className={`message ${message.role}`}>
                            <Typography variant="body1">
                                {message.content}
                            </Typography>
                        </Box>
                    ))}
                </Paper>
            </Box>
            <Box className="chat-input-wrapper">
                <Box className="chat-input">
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Type your message here..."
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                    />
                    <Button
                        variant="contained"
                        onClick={handleSendMessage}
                        className="send-button"
                    >
                        Send
                    </Button>
                </Box>
            </Box>
            <Snackbar open={showMessage.show} autoHideDuration={6000} onClose={handleCloseMessage}>
                <Alert onClose={handleCloseMessage} severity={showMessage.type} sx={{width: '100%'}}>
                    {showMessage.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ChatWithAI;