const environment = process.env.REACT_APP_ENVIRONMENT;
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const backendUrlApiV1 = backendUrl + '/api/v1'
const backendUrlApiV1Accounts = backendUrlApiV1 + '/accounts'
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const maxFileSize = 104857600; // 100 MB in bytes
const colorsConstants = [ // Light colors used as backgrounds
    '#F1F8E9', // Light honeydew
    '#E0F2F1', // Light teal
    '#FFE4E1', // Misty rose
    '#E8EAF6', // Light periwinkle
    '#F0E6FF', // Lavender mist
    '#E0F7FA', // Light cyan
    '#FFE0B2', // Light peach
    '#E6F3D0', // Light lime green
    '#FFF3E0', // Light orange
    '#FFF0B3', // Pale yellow
];

const colorsBright = [ // Strong colors used for writing
    '#FF6B6B', // Bright Red
    '#4ECDC4', // Turquoise
    '#45B7D1', // Sky Blue
    '#F7DC6F', // Yellow
    '#FFA07A', // Light Salmon
    '#98D8C8', // Mint
    '#9B59B6', // Purple
    '#3498DB', // Blue
    '#E74C3C', // Red
    '#2ECC71', // Green
];

// http://localhost:8000/auth/api/login/google/
const googleRedirectUri = backendUrl + '/auth/api/login/google/';

const config = {
    environment,
    backendUrl,
    backendUrlApiV1,
    backendUrlApiV1Accounts,
    googleClientId,
    googleRedirectUri,
    maxFileSize,
    colorsConstants,
    colorsBright
};

if (!environment) {
    throw new Error('REACT_APP_ENVIRONMENT is not set');
}
if (!backendUrl) {
    throw new Error('REACT_APP_BACKEND_URL is not set');
}

if (!googleClientId) {
    throw new Error('REACT_APP_GOOGLE_CLIENT_ID is not set');
}

if (!googleRedirectUri) {
    throw new Error('REACT_APP_GOOGLE_REDIRECT_URI is not set');
}

export default config;
