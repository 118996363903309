import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {Box, IconButton, Typography, Slider, CircularProgress, Grid, Tabs, Tab} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import './Heartbeat.css';
import HeartbeatTimeline from "./HeartbeatTimeline";
import Transcription from "../Transcription";
import config from '../../../config';
import ChatWithAI from "./ChatWithAI";
import Summary from "../Summary";

const MarkHighlights = () => <div>Mark highlights component</div>;

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Heartbeat = ({transcriptData}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isLoadingAudio, setIsLoadingAudio] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [transcriptState, setTranscriptState] = useState(transcriptData);
    const [tabIndex, setTabIndex] = useState(0);
    const [meetingIntelligence, setMeetingIntelligence] = useState(null);
    const audioRef = useRef(null);

    useEffect(() => {
        if (transcriptData && transcriptData.metaData && transcriptData.metaData.meetingId) {
            fetchAudioUrl(transcriptData.metaData.meetingId);
            fetchMeetingIntelligence(transcriptData.metaData.meetingId);
        }
    }, [transcriptData]);

    useEffect(() => {
        if (transcriptData) {
            setTranscriptState(prevState => ({
                ...prevState,
                ...transcriptData
            }));
        }
    }, [transcriptData]);

    const fetchAudioUrl = async (meetingId) => {
        setIsLoadingAudio(true);
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/get-audio-file/${meetingId}/`);
            setAudioUrl(response.data.url);
        } catch (error) {
            console.error('Error fetching audio URL:', error);
        } finally {
            setIsLoadingAudio(false);
        }
    };

    const fetchMeetingIntelligence = async (meetingId) => {
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/get-meeting-intelligence/${meetingId}/`);
            setMeetingIntelligence(response.data);
        } catch (error) {
            console.error('Error fetching meeting intelligence:', error);
        }
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.src = audioUrl;
            audioRef.current.load();
        }
    }, [audioUrl]);

    useEffect(() => {
        const audio = audioRef.current;
        const updateTime = () => {
            setCurrentTime(audio.currentTime);
        };
        const setAudioData = () => {
            setDuration(audio.duration);
        };
        audio.addEventListener('timeupdate', updateTime);
        audio.addEventListener('loadedmetadata', setAudioData);
        return () => {
            audio.removeEventListener('timeupdate', updateTime);
            audio.removeEventListener('loadedmetadata', setAudioData);
        };
    }, []);

    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };

    const skipTime = (seconds) => {
        const audio = audioRef.current;
        audio.currentTime = Math.min(Math.max(0, audio.currentTime + seconds), duration);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleSeek = (event, newValue) => {
        const audio = audioRef.current;
        audio.currentTime = newValue;
        setCurrentTime(newValue);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Grid container spacing={1} className="heartbeat-container">
            <Grid item xs={12} md={8}>
                {isLoadingAudio ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                        <CircularProgress/>
                    </Box>
                ) : (
                    <>
                        <Box className="playback-bar">
                            <Box className="slider-container">
                                <Slider
                                    className="seek-bar"
                                    value={currentTime}
                                    max={duration}
                                    onChange={handleSeek}
                                    aria-labelledby="continuous-slider"
                                />
                            </Box>
                            <Box className="controls">
                                <Typography variant="body2" className="time-display">
                                    {formatTime(currentTime)} / {formatTime(duration)}
                                </Typography>
                                <Box className="playback-controls">
                                    <IconButton onClick={() => skipTime(-15)} className="skip-backward">
                                        <SkipPreviousIcon/>
                                    </IconButton>
                                    <IconButton onClick={togglePlayPause} className="play-pause-button">
                                        {isPlaying ? <PauseIcon/> : <PlayArrowIcon/>}
                                    </IconButton>
                                    <IconButton onClick={() => skipTime(15)} className="skip-forward">
                                        <SkipNextIcon/>
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="timeline-container">
                            <HeartbeatTimeline
                                transcriptData={{
                                    ...transcriptData,
                                    meetingIntelligence: meetingIntelligence
                                }}
                                duration={duration}
                                currentTime={currentTime}
                            />
                        </Box>
                    </>
                )}
                <audio ref={audioRef}/>
            </Grid>
            <Grid item xs={12} md={4}>
                <Box className="grid-item grid-item-transcription heartbeat-tabview">
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Heartbeat Tabs">
                        <Tab label="Transcription"/>
                        <Tab label="Chat with AI"/>
                        <Tab label="Summary"/>
                    </Tabs>
                    <TabPanel value={tabIndex} index={0}>
                        <Transcription data={transcriptState} setData={setTranscriptState}/>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <ChatWithAI transcriptData={transcriptState}/>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        <Summary summaryData={transcriptState.summary}/>
                    </TabPanel>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Heartbeat;
