import React, { useState } from 'react';
import { Typography, Grid, Chip, Box, useTheme, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import InteractionMap from './InteractionMap';
import { PieChart, Pie, Cell, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';
import Transcription from "../Transcription";
import './MeetingAnalysis.css';
import IndicatorsRadarChart from './IndicatorsRadarChart';
import config from "../../../config";

const colors = config.colorsBright

const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
};

const MeetingAnalysis = ({ data, transcript }) => {
    const theme = useTheme();
    const [view, setView] = useState('all');
    const [transcriptData, setTranscriptData] = useState(transcript);

    if (!data || Object.keys(data).length === 0) {
        return null;
    }

    const viewOptions = [
        'all',
        ...Object.keys(data)
            .filter(key => key !== 'all')
            .sort((a, b) => a.localeCompare(b))
    ];

    const allData = data.all || {};
    const filteredData = view === 'all' ? allData : data[view] || {};

    const pieData = (allData.ratio || []).map(speaker => ({
        name: speaker.speakerName,
        value: speaker.speakerRatio,
    }));

    const radarData = (filteredData.llmIndicators || []).map(indicator => {
        const score = filteredData[indicator]?.score;
        return {
            indicator: indicator,
            score: typeof score === 'number' && !isNaN(score) ? score : 0
        };
    }).filter(item => item.score > 0);

    const analysisComponents = [
        {
            title: "Indicators",
            content: <IndicatorsRadarChart data={radarData} />,
            gridProps: { xs: 12, sm: 6 },
        },
        {
            title: "Speaking Time",
            content: (
                <div className="chart-container">
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                dataKey="value"
                                isAnimationActive={false}
                                data={pieData}
                                cx="50%"
                                cy="50%"
                                outerRadius="80%"
                                fill="#8884d8"
                                labelLine={true}
                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            >
                                {pieData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} id={index} fill={colors[index % colors.length]} />
                                ))}
                            </Pie>
                            <RechartsTooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            ),
            gridProps: { xs: 12, sm: 6 },
        },
        {
            title: "Meeting Stats",
            content: (
                <Grid container spacing={2}>
                    <Grid item>
                        <Chip label={`Total statements: ${filteredData.segments?.totalSegments || 0}`} className="chip" style={{ backgroundColor: getRandomColor() }} />
                    </Grid>
                    <Grid item>
                        <Chip label={`Statements / Min: ${Math.ceil((filteredData.segments?.segmentsPerHour || 0) / 60)}`} className="chip" style={{ backgroundColor: getRandomColor() }} />
                    </Grid>
                    <Grid item>
                        <Chip label={`Longest monologue: ${filteredData.segments?.segmentDurationMaxMs || 0}`} className="chip" style={{ backgroundColor: getRandomColor() }} />
                    </Grid>
                </Grid>
            ),
            gridProps: { xs: 12, sm: 6 },
        },
        {
            title: "Affirmations",
            content: (
                <Grid container spacing={2}>
                    <Grid item>
                        <Chip label={`Total Yes: ${filteredData.affirmations?.yes || 0}`} className="chip" style={{ backgroundColor: getRandomColor() }} />
                    </Grid>
                    <Grid item>
                        <Chip label={`Total No: ${filteredData.affirmations?.no || 0}`} className="chip" style={{ backgroundColor: getRandomColor() }} />
                    </Grid>
                    <Grid item>
                        <Chip label={`Yes / min: ${Math.ceil((filteredData.affirmations?.yesPerHour || 0) / 60)}`} className="chip" style={{ backgroundColor: getRandomColor() }} />
                    </Grid>
                    <Grid item>
                        <Chip label={`No / min: ${Math.ceil((filteredData.affirmations?.noPerHour || 0) / 60)}`} className="chip" style={{ backgroundColor: getRandomColor() }} />
                    </Grid>
                </Grid>
            ),
            gridProps: { xs: 12, sm: 6 },
        },
        {
            title: "Interaction Map",
            content: <InteractionMap data={filteredData.interactionMap} />,
            gridProps: { xs: 12, sm: 12 },
        },
    ];

    return (
        <Grid container spacing={1} className="analysis-container">
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="view-select-label">View</InputLabel>
                    <Select
                        labelId="view-select-label"
                        id="view-select"
                        value={view}
                        label="View"
                        onChange={(e) => setView(e.target.value)}
                    >
                        {viewOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option === 'all' ? 'All' : `${option}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={8} className="analysis-content">
                <Grid container spacing={1}>
                    {analysisComponents.map((component, index) => (
                        <Grid item {...component.gridProps} key={index} className="analysis-component">
                            <Box className="outlined-box">
                                <Typography variant="h6" gutterBottom>{component.title}</Typography>
                                <Box className="content-box">
                                    {component.content}
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
                <Box className="grid-item grid-item-transcription">
                    <Transcription data={transcriptData} setData={setTranscriptData} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default MeetingAnalysis;