// In src/auth/AuthCallback.js

import React, { useEffect } from 'react';
import {useAuth} from "./AuthProvider";
import {useNavigate} from "react-router-dom";

const AuthCallback = () => {
    const { handleLogin } = useAuth(); // Get handleGoogleLogin from AuthProvider
    const navigate = useNavigate(); // Initialize navigate
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        const email = params.get('email');

        const savedUser = localStorage.getItem('user');
        if (!savedUser && token && email) {
            handleLogin(token, email);
            navigate('/product');
        } else if (savedUser) {
            navigate('/product');
        } else {
            navigate('/sign-in');
        }

    }, [navigate, handleLogin]);

    return <div>Loading...</div>;
};

export default AuthCallback;
