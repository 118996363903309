import React from 'react';
import { NavLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import './Sidebar.css';
import {getRandomLogoPair} from "../utils";

const fontFamilies = ['Roboto Slab', 'Raleway', 'Montserrat', 'Playfair Display', 'Poppins'];
const font_index = 2;

const pair = getRandomLogoPair();

const sidebarItems = [
    // { to: '/product/home', icon: <HomeIcon />, text: 'Home' },
    { to: '/product/home', icon: <img src={pair.logo} alt="Elevaide Logo" className="elevaide-logo-image" />, text: 'Home' },
    { to: '/product/meet', icon: <MeetingRoomIcon />, text: 'Meetings' },
    // { to: '/product/analyse', icon: <AnalyticsIcon />, text: 'Analyse' },
    { to: '/product/meeting-chat', icon: <MarkChatUnreadIcon />, text: 'Chat' },
    { to: '/product/upload', icon: <CloudUploadIcon />, text: 'Upload' },
    // { to: '/product/feedback', icon: <FeedbackIcon />, text: 'Feedback' },
];

const Sidebar = () => {
    return (
        <Box className="styled-sidebar">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List>
                        {sidebarItems.map((item, index) => (
                            <ListItem button component={NavLink} to={item.to} key={index} className="styled-list-item">
                                <ListItemIcon className="styled-list-item-icon">
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.text}
                                    primaryTypographyProps={{
                                        variant: 'caption',
                                        fontFamily: fontFamilies[font_index],
                                    }}
                                    className="styled-list-item-text"
                                />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Sidebar;
